$fa-font-path: "../font";
$html-font-size: 16;
$white: #fff;
$black: #000;
$neon-green: #ADF317;
$neon-pink: #EA39CF;


@font-face {
  font-family: 'Cheyanneb';
  src: url(../font/cheyenneb.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oswald Bold';
  src: url(../font/Oswald-Bold.woff) format('woff');
  font-weight: Normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oswald Medium';
  src: url(../font/Oswald-Medium.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oswald Regular';
  src: url(../font/Oswald-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$primary-font: 'Oswald Regular', Arial, sans-serif;
$primary-font-medium: 'Oswald Medium', Arial, sans-serif;
$primary-font-bold: 'Oswald Bold', Arial, sans-serif;
$secondary-font: 'Cheyanneb', Arial, serif;
