@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	background: $black;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
	width: 100%;
	overflow-x: hidden;
}

a {
	color: inherit;

	@include hover-focus {
		color: $white;
	}
}

#site-wrapper {
	min-height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;

	background-image: url(../img/bg/texture-bg.png);
	background-position: center;
	background-size: contain;
}

header {
	#navbar {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		background-color: transparent;

		.container {
			display: flex;
			align-items: flex-start;
			justify-content: flex-end;
			max-width: rems(1600);
			margin-top: rems(28);

			#logo {
				max-width: rems(400);
				margin-right: auto;
				pointer-events: auto;
				cursor: pointer;
				position: relative;
				z-index: 15;

				@media screen and (max-width: 700px) {
					max-width: rems(250);
				}
			}

			.list-wrapper {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				position: relative;
				z-index: 10;

				@media screen and (max-width: 1100px) {
					display: none;
					padding-top: rems(70);
				}

				#nav-links {
					display: flex;
					align-items: flex-start;
					font-family: $secondary-font;
					font-size: rems(30);
					color: $neon-green;
					padding: rems(50) 0;

					li {
						list-style: none;
						padding: 0 rems(16);
						text-transform: uppercase;
						position: relative;

						.marked {
							&::before {
								content: url(../img/active-decal.png);
								position: absolute;
								top: 100%;
								left: 0;
							}
						}
					}
				}

				.social {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: rems(10);
					padding-right: rems(50);
					min-height: rems(40);
					min-width: rems(40);


					li {
						list-style: none;

						@include hover-focus {
							transition: all 0.3s ease-in-out;
							transform: scale(1.2);
						}

						a {
							img {
								height: rems(42);
								width: rems(42);
							}
						}
					}
				}

				&.active {
					position: fixed;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					pointer-events: all;
					background-color: #000a;

					#nav-links {
						flex-direction: column;
						font-size: rems(60);
						gap: rems(96);
						align-items: center;
					}
				}
			}

			.hamburger {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 60px;
				height: 50px;
				pointer-events: all;
				cursor: pointer;
				position: relative;
				z-index: 30;

				@media screen and (min-width: 1101px) {
					display: none;
				}

				@media screen and (max-width: 700px) {
					margin: rems(40);
				}
			}

			.line {
				width: 100%;
				height: 9px;
				background-color: #ADF317;
				border: 1px solid #DE42C5;
				margin: 4.5px;
				transition: transform 0.3s ease;
				transform-origin: center;


				&.active {
					transform: rotate(45deg) !important;
					position: absolute;
					top: 30%;

					&.active:nth-child(2) {
						display: none;
					}

					&.active:nth-child(3) {
						transform: rotate(-45deg) !important;
					}
				}
			}
		}
	}
}

main {
	width: 100%;
	flex: 1;
	position: relative;

	section {
		padding-top: rems(257);

		@media screen and (max-width: 700px) {
			padding-top: rems(300);
		}
	}

	.sketch1 {
		position: absolute;
		top: rems(257);
		left: 0;
		width: 28%;
		max-width: rems(544);
		margin: 0 0 rems(80) rems(85);

		@media screen and (max-width: 700px) {
			display: none;
		}
	}

	.sketch2 {
		position: absolute;
		top: rems(257);
		right: 0;
		width: 23%;
		max-width: rems(446);
		margin: 0 rems(32) rems(61) 0;

		@media screen and (max-width: 1200px) {
			display: none;
		}
	}

	#hero {
		width: 100%;
		height: 100vh;
		background-image: url(../img/bg/hero.png);
		background-size: cover;
		background-position: center;
		display: none;
		pointer-events: none;
		padding-top: 0;

		&.active {
			display: block;
			pointer-events: all;
		}
	}

	.responsive-bg {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		max-height: 100vh;
		display: none;

		&.active {
			display: block;
		}
	}

	#bio {
		display: none;
		pointer-events: none;
		justify-content: center;

		&.active {
			display: flex;
			pointer-events: all;
		}

		.message {
			width: 40%;
			transform: translateX(rems(70));
			padding-bottom: rems(100);

			@media screen and (max-width: 1200px) {
				width: 50%;
				transform: translateX(rems(150));
			}

			@media screen and (max-width: 700px) {
				width: 80%;
				transform: none;
			}
		}
	}

	#tour {
		display: none;
		pointer-events: none;
		justify-content: center;

		&.active {
			display: flex;
			pointer-events: all;
		}

		.tour-embed {
			width: 40%;
			transform: translateX(rems(70));
			padding-bottom: rems(60);

			@media screen and (max-width: 1200px) {
				width: 50%;
				transform: translateX(rems(150));
			}

			@media screen and (max-width: 700px) {
				width: 80%;
				transform: none;
			}

			#seated-55fdf2c0 {
				.seated-events-table {
					border: none;
					font-size: rems(20);
					display: flex;
					flex-direction: column;
					gap: rems(16);
					// padding: 0 0 rems(57);

					@media screen and (max-width: 700px) {
						text-align: center;
						gap: rems(20);
					}

					@media screen and (max-width: 1100px) {
						flex-direction: column;
						align-items: center;
						gap: rems(20);
					}

					.seated-event-row {
						border: none;

						@media screen and (max-width: 1100px) {
							text-align: center;
							display: flex;
							flex-direction: column;
							align-items: center;
						}

						.seated-event-description-cells {
							display: flex;
							flex-direction: column;
							gap: rems(10);

							.seated-event-date-cell {
								// text-transform: capitalize;
								// padding-bottom: rems(20);
								// margin: rems(50);
							}

							.seated-event-venue-cell {
								// text-transform: capitalize;
							}

							.seated-event-detail-cell {
								opacity: 0.7;
								display: flex;
								align-items: center;
							}

						}

						.seated-event-link-cells {
							.seated-event-link {
								display: inline-block;
								text-align: center;
								background-color: $neon-pink;
								color: $black;
								font-family: $secondary-font;
								font-size: rems(18);
								padding: rems(12) rems(24) rems(16);
								width: 240px;
								margin: 0;

								@include hover-focus {
									transition: all 0.3s ease-in-out;
									background-color: $white;
								}
							}
						}
					}
				}


				.no-events {
					font-size: rems(24);
					text-align: center;
				}
			}
		}

		// #tour-dates {
		// 	width: 40%;
		// 	transform: translateX(rems(70));
		// 	padding-bottom: rems(60);

		// 	@media screen and (max-width: 1200px) {
		// 		width: 50%;
		// 		transform: translateX(rems(150));
		// 	}

		// 	@media screen and (max-width: 700px) {
		// 		width: 80%;
		// 		transform: none;
		// 	}

		// 	.no-events {
		// 		font-size: rems(24);
		// 		text-align: center;
		// 	}

		// 	.event-group {
		// 		display: flex;
		// 		font-size: rems(20);
		// 		padding: 0 0 rems(57);

		// 		@media screen and (max-width: 700px) {
		// 			flex-direction: column;
		// 			text-align: center;
		// 			gap: rems(20);
		// 		}

		// 		@media screen and (max-width: 1000px) {
		// 			flex-direction: column;
		// 			align-items: center;
		// 			gap: rems(20);
		// 		}

		// 		.group {
		// 			flex: 1;

		// 			@media screen and (max-width: 1000px) {
		// 				text-align: center;
		// 			}

		// 			.event-date {
		// 				flex: 1;
		// 				// text-transform: capitalize;
		// 				padding-bottom: rems(20);
		// 			}

		// 			.event-venue {
		// 				flex: 1;
		// 				// text-transform: capitalize;
		// 			}
		// 		}

		// 		.event-location {
		// 			flex: 1;
		// 			opacity: 0.7;
		// 			display: flex;
		// 			align-items: center;
		// 		}

		// 		.event-links {

		// 			a {
		// 				flex: 1;
		// 				display: inline-block;
		// 				text-align: center;
		// 				background-color: $neon-pink;
		// 				color: $black;
		// 				font-family: $secondary-font;
		// 				font-size: rems(18);
		// 				padding: rems(8) rems(42) rems(13);

		// 				@include hover-focus {
		// 					transition: all 0.3s ease-in-out;
		// 					background-color: $white;
		// 				}
		// 			}
		// 		}
		// 	}
		// }
	}

	#fanart {
		display: none;
		pointer-events: none;

		&.active {
			display: flex;
			justify-content: flex-end;
			pointer-events: all;
		}

		.sketch1 {

			@media screen and (max-width: 1300px) {
				margin: 0 0 rems(80) rems(50);
			}

			@media screen and (max-width: 1100px) {
				display: none;
			}
		}

		#instagram-feed {
			display: flex;
			flex-flow: row wrap;
			position: relative;
			z-index: 1;
			width: 60%;
			margin: 0 rems(100) rems(100) 0;

			@media screen and (max-width: 1100px) {
				width: 100%;
				padding: rems(20);
				margin: 0;
			}

			>div {
				width: 33%;
				padding: 1%;

				@media screen and (max-width: 600px) {
					flex-basis: 100%;
					margin: rems(15) rems(50);
				}

				a {
					background-repeat: no-repeat;
					background-size: cover;
					display: block;
					overflow: hidden;
					padding-bottom: 100%;
					position: relative;
					width: 100%;

					span {
						align-items: center;
						background-color: rgba(0, 0, 0, .7);
						color: $white;
						display: flex;
						font-weight: 300;
						justify-content: center;
						line-height: 1.3;
						padding: rems(15);
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						transform: translateX(-100%);
						transition: transform .3s;
					}

					&:active,
					&:focus,
					&:hover {
						span {
							transform: scale(1.1);
						}
					}
				}
			}
		}
	}
}

footer {}